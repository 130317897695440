<template>
  <div>
    <header-slot> </header-slot>
    <b-row>
      <b-col cols="6" class="mt-1">
        <DepartmentExpenses />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DepartmentExpenses from "@/views/commons/expenses/components/department/DepartmentExpenses.vue";

export default {
  components: {
    DepartmentExpenses,
  },
};
</script>

<style>
</style>
